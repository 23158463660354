import { useState, useCallback } from 'react';
import { endpoint } from './lib/endpoint';

const authorizeUrl = `${endpoint}/authenticate`

function authorize() {
  const url = `${authorizeUrl}?redirectURI=${encodeURIComponent(window.location.toString())}`;
  window.location.replace(url);
}

type AuthenticatedFetch = [() => Promise<Response>, boolean];

export default function useAuthenticatedFetch(url: string): AuthenticatedFetch {
  const [authorizing, setAuthorizing] = useState<boolean>(false);

  const fetch = useCallback(async () => {
    const result = await global.fetch(url, {
      credentials: "include"
    });
    if (result.status === 401) {
      // redirect to authorize
      authorize();
      setAuthorizing(true);
      await new Promise(res => setTimeout(res, 10000)); // TODO: crude way of "waiting"

      // TODO: we should have loaded authorizeUrl, but what if not?
    }
    return result;
  }, [url]);

  return [fetch, authorizing];
}
