import React from "react";
import { BounceLoader } from "react-spinners";
import {Flex, useThemeUI} from "theme-ui";

const CircularProgress = () => {
  const { theme } = useThemeUI();
  console.log("theme?", theme);

  return (
    <div className="fade-in-once">
      <Flex sx={{ p: 2, flexDirection: "column", alignItems: "center" }}>
        <BounceLoader color={theme.colors.primary || "gray"} />
      </Flex>
    </div>
  );
};

export default () => (<CircularProgress />);
