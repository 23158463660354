/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {navigate} from "gatsby";
import Place from "@mui/icons-material/Place";
import ActionButton from "../components/ActionButton";
import PublicAreaList from "../components/PublicAreaList";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, null, "Find Listings"), "\n", React.createElement(ActionButton, {
    text: "Use my Location",
    icon: React.createElement(Place),
    onClick: () => navigate('/listings-by-location')
  }), "\n", React.createElement(PublicAreaList));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
