import React, {useState} from "react";
import {Box, Button, Card, Flex, Grid, Text, Link as ThemedLink, NavLink} from "theme-ui";
import {navigate, Link} from "gatsby";
import {endpoint} from "../lib/endpoint";
import AuthenticatedDataView from "./AuthenticatedDataView";
import SuburbInProvinceSelectorView from "./SuburbInProvinceSelectorView";
import ActionButton from "./ActionButton"


const ProvinceInList = ({ area, onProvinceSelected }) => {
  return <Card sx={{ maxWidth: 300, mr: 2, p: 2 }} onClick={onProvinceSelected}>
    <Text sx={{ fontSize: 3, fontWeight: 'bold' }}>{area.name}</Text>
    <Box>
      <Text>{area.num_listings} listings</Text>
    </Box>
  </Card>;
}

type Province = {
  id: number,
  name: string,
  num_listings: number
}

const ProvinceBreadcrumbs = ({ province, onClear }) => (
  <Text>
    <ThemedLink as={NavLink} sx={{
      color: 'inherit',
      '&:hover': {
        cursor: 'pointer'
      },
      '&.active': {
        color: 'primary',
      },
    }} onClick={onClear}>All areas</ThemedLink>
    {" > "}
    <Text>{province.name}</Text>
  </Text>
);

const SelectedProvinceView = ({ province, onClear }) => {
  return <>
    <Box my={3}>
      <ProvinceBreadcrumbs province={province} onClear={onClear}/>
    </Box>
    <Box my={3}>
      <SuburbInProvinceSelectorView province={province} />
    </Box>
  </>
}

type ProvinceAndSuburbSelectViewProps = {
  provinces: Province[];
}

const ProvinceAndSuburbSelectView = ({ provinces }: ProvinceAndSuburbSelectViewProps) => {
  const [province, setProvince] = useState<Province | null>(null);
  
  if (province) {
    return <SelectedProvinceView province={province} onClear={() => setProvince(null)} />
  }

  return <Grid width={[128, null, 192]}>
    { provinces.filter(a => a.num_listings > 0).map(area => (
      <ProvinceInList key={area.id} area={area} onProvinceSelected={() => setProvince(area)} />
    ))}
  </Grid>;
}

export default () => {
  const [showProvinces, setShowProvinces] = useState(false);

  return <AuthenticatedDataView fetchUrl={`${endpoint}/provinces`}
    render={data => {
      const { totalCount, provinces } = data;
      console.log('totalCount', totalCount);
      return <Box py={4}>
        { !showProvinces && <>
          <Box pb={3}>
            <Text>Alternatively, you can pick a province, and look for your suburb.</Text>
          </Box>
          <ActionButton variant="secondary" text="Show Provinces" onClick={() => setShowProvinces(true)}/>
        </> }
        { showProvinces &&
          <ProvinceAndSuburbSelectView provinces={provinces as Province[]} />
        }
      </Box>;
    }}
  />;
};
