import React, { useCallback, useEffect, useState } from "react";
import { Divider, Message, Text } from "theme-ui";
import LoadingIndicator from "./LoadingIndicator";
import useAuthenticatedFetch from "../useAuthenticatedFetch";

// component to inject data into children, based on an authenticatedFetch

type AuthenticatedDataViewProps = {
  render: (data: any) => JSX.Element,
  fetchUrl: string,
}

function useLoadedData<T>(url: string) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const [data, setData] = useState<T | undefined>(undefined);

  const [fetchAuthenticated, loggingIn] = useAuthenticatedFetch(url);

  useEffect(() => {
    if (!loading) {
      return;
    }
    fetchAuthenticated()
    .then(response => {
      if (!response.ok) {
        console.error('response not ok', response);
        throw new Error(`Response not ok, status is ${response.status} ${response.statusText}`);
      }
      return response.text();
    })
    .then(body => {
      if (loading) {
        setData(JSON.parse(body));
        setLoading(false);
      }
    })
    .catch(setError);
    return () => setLoading(false);
  }, [loading, fetchAuthenticated]);

  useEffect(() => {
    setLoading(true);
  }, [url]);

  return { isLoading: loading || loggingIn, error, data }
}

export default ({ render, fetchUrl }: AuthenticatedDataViewProps) => {

  const { isLoading, error, data } = useLoadedData(fetchUrl);

  const refresh = useCallback(() => {
    location.reload();
  }, []);

  if (error) {
    return <section>
      <Message>
        <Text>Error: {error.message}</Text>
        <Divider/>
        <Text>Please <a href='#' onClick={refresh}>refresh</a> to try again.</Text>
      </Message>
      { error.stack &&
        <pre>{JSON.stringify(error.stack, null, 2)}</pre>
      }
    </section>
  }
  return <section>
    { isLoading && <LoadingIndicator/> }
    { !isLoading && data && render(data) }
  </section>
}
