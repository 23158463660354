import React, {useState} from "react";
import {Box, Button, Card, Close, Flex, Input, Text, NavLink, Link as ThemedLink} from "theme-ui";
import {navigate } from "gatsby";
import {endpoint} from "../lib/endpoint";
import useDebounce from "../lib/useDebounce";
import AuthenticatedDataView from "./AuthenticatedDataView";
import useAuthenticatedFetch from "../useAuthenticatedFetch";
import LoadingIndicator from "./LoadingIndicator";

type Province = {
  id: number
};

type Props = {
  province: Province
};

const SuburbInList = ({ suburb, onClick }) => {
  const [fetchSuburbDetails, _] = useAuthenticatedFetch(`${endpoint}/suburbs/${suburb.id}`);
  const [isLoading, setLoading] = useState(false);
  return <Card variant="secondary" onClick={() => {
    setLoading(true);
    fetchSuburbDetails().then(data => {
      return data.json();
    }).then(data => {
      const coords = data.suburb.envelope_centroid;
      navigate(`/listings-at-location?lat=${coords[1]}&lon=${coords[0]}`);
    }).catch(err => {
      alert("Oops, unable to fetch details, please refresh and try again. Message: " + err.message);
    }).finally(() => {
      setLoading(false);
    });
  }} sx={{p: 2, mt: 2}}>
    <Text>
      {suburb.name}
    </Text>
    { isLoading && <LoadingIndicator /> }
    </Card>
};

export default ({ province }: Props) => {
  const [searchText, setSearchText] = useState<string>("");
  const [limit, setLimit] = useState<number>(15);
  const debouncedSearchText = useDebounce(searchText, 1000);
  let fetchUrl = `${endpoint}/suburbs/by_province?province_id=${province.id}&limit=${limit}`;
  if (debouncedSearchText.length > 0) {
    fetchUrl += `&q=${debouncedSearchText}`;
  }
  return <>
    <Flex sx={{ flexDirection: "row", alignItems: "center" }}>
      <Input type="text" placeholder="Search here" value={searchText} onChange={e => setSearchText(e.target.value)}/>
      { searchText.length > 0 &&
        <Close sx={{ '&:hover': { cursor: 'pointer' } }} onClick={() => setSearchText("")} />
      }
    </Flex>
    <AuthenticatedDataView fetchUrl={fetchUrl}
      render={data => {
        return <Box mt={3}>
          { (!data.suburbs || data.suburbs.length < 1) &&
            <Text>No listings found here. Add one, if you know of one, maybe?</Text>
          }
          { data.suburbs.map(suburb => (
            <SuburbInList key={suburb.id} suburb={suburb} onClick={() => {
              navigate('/listings-by-suburb/' + suburb.id);
            }} />
          )) }
          { limit <= data.suburbs.length &&
            <ThemedLink as={NavLink} sx={{
              px: 3, 
              color: 'inherit',
              '&:hover': {
                cursor: 'pointer'
              },
              '&.active': {
                color: 'primary',
              },
            }} onClick={() => setLimit(limit + 10)}>More...</ThemedLink>
          }
        </Box>
      }}
    />
  </>
}

