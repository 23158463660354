import React, {ReactNode} from "react";
import {Box, Button, Flex} from "theme-ui";

type ActionButtonProps = {
  icon?: ReactNode,
  rightIcon?: ReactNode,
  text: string,
  onClick?: () => void | Promise<void>,
  variant?: 'primary' | 'secondary',
  disabled?: boolean
};

const ActionButton = ({ icon, rightIcon, text, onClick, variant, disabled }: ActionButtonProps) => {
  return <Button disabled={disabled} variant={ variant || "primary"} onClick={onClick}>
    <Flex sx={{ flexDirection: "row", alignItems: "center" }}>
      { icon && <>{icon}&nbsp;</> }
      {text}
      { rightIcon && <><Box ml={2} /><>{rightIcon}</></> }
    </Flex>
  </Button>;
};

export default ActionButton;
